import { Collapse } from 'antd'
import Loading from 'components/loading'
import Table from 'components/table'
import { groupBy, map, omit } from 'lodash'
import PanelHeader from 'modules/configuration/components/panelHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronBack } from 'react-icons/io5'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formatCurrency } from 'utils/currency/formatCurrency'
import NovoConfgInfoCard from '../components/NovoConfgInfoCard'
import { getStockById } from '../query'

type CUSTOMTYPE = {
    [key: string | number]: string | number
}

const { Panel } = Collapse

const StockDetail = () => {
    const { t } = useTranslation()

    const { stockId } = useParams()

    const [quoteData, setQuoteData] = useState<any>({})
    const [novoData, setNovoData] = useState<any>({})
    const [originalData, setOriginalData] = useState<any>({})
    const [showOptional, setShowOptional] = useState<boolean>(false)
    const [optionalData, setOptionalData] = useState<any>({} as any)
    const [novoConfigurationExtraData, setNovoConfigurationExtraData] = useState<any>({} as any)

    const columns: any = [
        {
            id: 'part_number',
            name: t('api.part_number'),
            left: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{row['Part Number'] || ''}</p>
            },
        },
        {
            id: 'description',
            name: t('api.part_description'),
            left: true,
            height: 'fit-content',
            wrap: true,
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left py-2">{row['Part Description'] || ''}</p>
            },
        },
        {
            id: 'quantity',
            name: t('api.quantity'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '120px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{row?.Quantity}</p>
            },
        },
        {
            id: 'discount',
            name: t('api.discount'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '120px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{row?.Discount}</p>
            },
        },
        {
            id: 'list_price',
            name: t('api.list_price'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: CUSTOMTYPE) => {
                return <p className="text-md text-left">{formatCurrency(row['List Price'] || 0)}</p>
            },
        },
        {
            id: 'net_price',
            name: t('api.net_price_each'),
            center: true,
            height: 'fit-content',
            wrap: true,
            width: '200px',
            selector: (row: CUSTOMTYPE) => {
                return (
                    <p className="text-md text-left">
                        {formatCurrency(row['Net Price Each'] || 0)}
                    </p>
                )
            },
        },
    ]

    const mappedData = (configurationData: any) => {
        const parsedData = JSON.parse(configurationData)
        const optionalNovo: any = []
        const nonOptionalNovo: any = []

        map(parsedData, item => {
            if (item.Optional === 'Yes') {
                optionalNovo.push(item)
            } else {
                nonOptionalNovo.push(item)
            }
        })

        setQuoteData(groupBy(nonOptionalNovo, 'Included Items') || {})
        setOptionalData(groupBy(optionalNovo, 'Included Items') || {})
        // for restoring original data to update data.
        setNovoData(parsedData)
    }

    const { isLoading, isFetching, refetch } = useQuery(
        ['getStockById'],
        () => getStockById(Number(stockId)),
        {
            onSuccess: data => {
                const configurationData = data?.data?.data?.configuration_data
                if (configurationData) {
                    mappedData(configurationData)
                    setOriginalData(configurationData || {})
                    setNovoConfigurationExtraData(data?.data?.data)
                }
            },
            onError: (error: Error) => {
                toast.error(error.message || t('fetch_stock_by_id_fail'))
            },
        },
    )

    if (isLoading || isFetching) {
        return (
            <div className="h-[84vh] flex w-full justify-center items-center">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-8 mt-4 mr-4">
            <Link
                to="/novo-configuration"
                state={{
                    activeTab: {
                        name: 'api.stock',
                        value: 'stock',
                    },
                }}
                className="flex items-center gap-1 text-lg font-medium"
            >
                <span>
                    <IoChevronBack size={20} />
                </span>
                <span>{t('api.back')}</span>
            </Link>
            <NovoConfgInfoCard
                isLithium={novoConfigurationExtraData?.lithium_battery}
                isDeleted={novoConfigurationExtraData?.is_deleted}
                machine={(quoteData && quoteData?.Model && quoteData?.Model[0]) || {}}
                handleOptional={() => setShowOptional(!showOptional)}
                showOptional={false}
                hideButton={false}
                versionData={novoConfigurationExtraData}
            />
            <div className="w-full">
                <h3 className="text-secondary border-primary text-lg font-medium underline underline-offset-8 decoration-4 decoration-primary">
                    {t('api.parts')}
                </h3>
                <Collapse
                    style={{ backgroundColor: 'transparent' }}
                    className="w-full"
                    defaultActiveKey={Object.keys(omit(quoteData, ['Model']))}
                >
                    {map(omit(quoteData, ['Model']), (value, key) => {
                        return (
                            <Panel key={key} header={PanelHeader({ title: key })}>
                                <Table
                                    columns={columns}
                                    data={value || []}
                                    pagination={false}
                                    striped={false}
                                />
                            </Panel>
                        )
                    })}
                </Collapse>
            </div>
        </div>
    )
}

export default StockDetail
